<template>
    <modal name="lotteryShow" :width="500" :height="280" draggable="window-header">
            <div class="col-md-12 pb-10 text-center">
                <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">Lottery</h3>
            </div>
            <div class="col-md-12">
                <p style="color:green">Congratulations, You are our {{ this.position ?? 'N/A' }} lucky winner.</p>
            </div>
            <div class="col-md-12 row">
                <div class="col-md-4">
                    <label for="remark">Customer name</label>
                </div>
                <div class="col-md-8">
                    <div class="form-control input-small">{{ this.selecteduser?.name }} ({{
                        this.selecteduser?.generatedid }})</div>
                </div>
            </div>
            <div class="col-md-12 row mt-20">
                <div class="col-md-4">
                    <label for="remark">Reward</label>
                </div>
                <div class="col-md-8">
                    <textarea class="form-control " v-model="rewards"></textarea>
                </div>
            </div>
            <div class="col-md-12 flex-between-row pt-20">
                <button class="btn bg-danger text-white btn-sm chkcondition" @click="close()">Cancel</button>
                <button class="btn bg-success text-white btn-sm chkcondition" @click="lotterysave()">Submit</button>
            </div>
        </modal>
</template>

<script>
export default {
    props: ['selecteduser','position'],

    data() {
        return {
            rewards: '',
        };
    },
    mounted(){

    },
    methods: {
        
        close() {
            this.rewards = ''
            this.$modal.hide('lotteryShow')
        },
        lotterysave() {
            if (this.rewards != '') {
                let param = {
                    customerid: this.selecteduser.id,
                    customername: this.selecteduser.name,
                    generatedid: this.selecteduser.generatedid,
                    rewards: this.rewards,
                    position: this.position ?? '1',
                }
                this.$http.post('api/lottery/record/save', param)
                    .then((response) => {
                        console.log(response)
                        this.$notify({
                            title: 'Success',
                            message: 'Record saved successfully!',
                            type: 'success',
                            duration: 2000
                        })
                        this.close()
                    })
                    .catch(error => console.log(error));
            }
        }
    }
}
</script>